var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading && !_vm.showOverlay)?_c('div',{staticClass:"d-flex justify-content-center mt-2 mb-2"},[_c('b-spinner')],1):_c('div',[_c('b-overlay',{attrs:{"show":_vm.showOverlay}},[_c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"header p-2"},[_c('h4',[_vm._v(" "+_vm._s(_vm.T( "Web.InvoiceInspectionsPage.AwatingApproval", "Awating approval" ))+" ("+_vm._s(_vm.inspectionsAwaitingApproval.length)+") ")])]),_c('b-table',{attrs:{"responsive":"","fields":_vm.tableFields,"items":_vm.inspectionsAwaitingApproval,"show-empty":"","empty-text":_vm.T('Web.Generic.TableEmpty', 'No items to show')},scopedSlots:_vm._u([{key:"cell(invoiceNumber)",fn:function(data){return [_c('b-link',{staticClass:"font-weight-bold",attrs:{"to":{
                name: 'economics.invoices.preview',
                params: { id: data.item.invoiceId },
              }}},[_vm._v(" #"+_vm._s(data.value)+" ")])]}},{key:"cell(inspectionPointsPerformed)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('strong',[_vm._v(_vm._s(data.value.length))]),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
                  _vm.GetInspectionPointsPerformedToolTipText(data.value)
                ),expression:"\n                  GetInspectionPointsPerformedToolTipText(data.value)\n                ",modifiers:{"hover":true,"right":true}}],attrs:{"icon":"InfoIcon"}})],1)]}},{key:"cell(passFailed)",fn:function(data){return [_c('small',{staticClass:"p-0"},[_vm._v(" "+_vm._s(_vm.T("Web.InvoiceInspectionsPage.Failed", "Failed"))+": "),_c('span',{staticClass:"text-danger font-weight-bold"},[_vm._v(_vm._s(data.item.failedInspectionPointsCount))]),_c('br'),_vm._v(" "+_vm._s(_vm.T("Web.InvoiceInspectionsPage.Passed", "Passed"))+": "),_c('span',{staticClass:"text-primary font-weight-bold"},[_vm._v(_vm._s(data.item.passedInspectionPointsCount))]),_c('br'),_vm._v(" "+_vm._s(_vm.T( "Web.InvoiceInspectionsPage.PassedWithWarnings", "Passed with warnings" ))+": "),_c('span',{staticClass:"text-warning font-weight-bold"},[_vm._v(_vm._s(data.item.passedWithWarningsInspectionPointsCount))])])]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"flat-secondary","size":"sm"},on:{"click":function($event){return _vm.handleRerunClicked(data.item)}}},[_c('feather-icon',{attrs:{"icon":"RotateCcwIcon"}}),_vm._v(" "+_vm._s(_vm.T( "Web.InvoiceInspectionsPage.RerunInspection", "Re-run inspection" ))+": ")],1),_c('b-button',{attrs:{"variant":"flat-primary","size":"sm","to":{
                  name: 'economics.invoice-inspections.show',
                  params: { id: data.item.id },
                }}},[_vm._v(_vm._s(_vm.T( "Web.InvoiceInspectionsPage.ReviewInspection", "Review inspection" ))+" "),_c('feather-icon',{attrs:{"icon":"ChevronRightIcon"}})],1)],1)]}}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }