<template>
  <div>
    <div
      class="d-flex justify-content-center mt-2 mb-2"
      v-if="loading && !showOverlay"
    >
      <b-spinner />
    </div>
    <div v-else>
      <b-overlay :show="showOverlay">
        <b-card no-body>
          <div class="header p-2">
            <h4>
              {{
                T(
                  "Web.InvoiceInspectionsPage.AwatingApproval",
                  "Awating approval"
                )
              }}
              ({{ inspectionsAwaitingApproval.length }})
            </h4>
          </div>
          <b-table
            responsive
            :fields="tableFields"
            :items="inspectionsAwaitingApproval"
            show-empty
            :empty-text="T('Web.Generic.TableEmpty', 'No items to show')"
          >
            <template #cell(invoiceNumber)="data">
              <b-link
                :to="{
                  name: 'economics.invoices.preview',
                  params: { id: data.item.invoiceId },
                }"
                class="font-weight-bold"
              >
                #{{ data.value }}
              </b-link>
            </template>
            <template #cell(inspectionPointsPerformed)="data">
              <div class="d-flex justify-content-between align-items-center">
                <strong>{{ data.value.length }}</strong>
                <feather-icon
                  icon="InfoIcon"
                  v-b-tooltip.hover.right="
                    GetInspectionPointsPerformedToolTipText(data.value)
                  "
                />
              </div>
            </template>
            <template #cell(passFailed)="data">
              <small class="p-0">
                {{ T("Web.InvoiceInspectionsPage.Failed", "Failed") }}:
                <span class="text-danger font-weight-bold">{{
                  data.item.failedInspectionPointsCount
                }}</span
                ><br />
                {{ T("Web.InvoiceInspectionsPage.Passed", "Passed") }}:
                <span class="text-primary font-weight-bold">{{
                  data.item.passedInspectionPointsCount
                }}</span
                ><br />
                {{
                  T(
                    "Web.InvoiceInspectionsPage.PassedWithWarnings",
                    "Passed with warnings"
                  )
                }}:
                <span class="text-warning font-weight-bold">{{
                  data.item.passedWithWarningsInspectionPointsCount
                }}</span>
              </small>
            </template>
            <template #cell(actions)="data">
              <div class="text-right">
                <b-button
                  variant="flat-secondary"
                  size="sm"
                  @click="handleRerunClicked(data.item)"
                >
                  <feather-icon icon="RotateCcwIcon" />
                  {{
                    T(
                      "Web.InvoiceInspectionsPage.RerunInspection",
                      "Re-run inspection"
                    )
                  }}:
                </b-button>
                <b-button
                  variant="flat-primary"
                  size="sm"
                  :to="{
                    name: 'economics.invoice-inspections.show',
                    params: { id: data.item.id },
                  }"
                  >{{
                    T(
                      "Web.InvoiceInspectionsPage.ReviewInspection",
                      "Review inspection"
                    )
                  }}
                  <feather-icon icon="ChevronRightIcon"
                /></b-button>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BTable,
  BCard,
  BCardTitle,
  BButton,
  VBTooltip,
  BSpinner,
  BLink,
  BOverlay,
} from "bootstrap-vue";
import InvoiceInspectionsService from "@/services/economics/invoice-inspections.service";
export default {
  components: {
    BTable,
    BCard,
    BCardTitle,
    BButton,
    VBTooltip,
    BSpinner,
    BLink,
    BOverlay,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  mounted() {
    this.fetchAwatingApproval();
  },
  data() {
    return {
      showOverlay: false,
      tableFields: [
        {
          key: "invoiceNumber",
          label: this.T("Web.Generic.Invoices.InvoiceNumber", "Invoice number"),
          sortable: true,
        },
        {
          key: "inspectionPointsPerformed",
          label: this.T(
            "Web.InvoiceInspectionsPage.InspectionPointsPerformed",
            "Inspection points performed"
          ),
          sortable: false,
        },
        {
          key: "passFailed",
          label: this.T(
            "Web.InvoiceInspectionsPage.FailedParsed",
            "Failed / parsed"
          ),
          sortable: false,
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      inspectionsAwaitingApproval:
        "invoiceInspections/inspectionsAwaitingApproval",
      loading: "invoiceInspections/isLoading",
    }),
  },
  methods: {
    ...mapActions({
      fetchAwatingApproval: "invoiceInspections/fetchAppendingApproval",
    }),
    GetInspectionPointsPerformedToolTipText(inspectionPoints) {
      return inspectionPoints
        .map((p) => this.T(`Web.InvoiceInspectionsPage.InspectionPoints.${p}.Title`))
        .join(", ");
    },
    async handleRerunClicked(inspection) {
      try {
        this.showOverlay = true;
        const service = new InvoiceInspectionsService();
        await service.rerunForInvoice(inspection.invoiceId);
        await this.fetchAwatingApproval();
      } finally {
        this.showOverlay = false;
      }
    },
  },
};
</script>

<style scoped>
.tooltip-inner {
  white-space: pre-wrap;
}
</style>