<template>
  <div>
    <div class="d-flex justify-content-center mt-2 mb-2" v-if="loading">
      <b-spinner />
    </div>
    <div v-else>
      <b-card no-body>
        <div class="header p-2">
          <h4>
            {{ T("Web.InvoiceInspectionsPage.Approved", "Approved") }}
            ({{ inspectionsApproved.length }})
          </h4>
        </div>
        <b-table
          responsive
          :fields="tableFields"
          :items="inspectionsApproved"
          show-empty
            :empty-text="T('Web.Generic.TableEmpty', 'No items to show')"
        >
          <template #cell(invoiceNumber)="data">
            <b-link
              :to="{
                name: 'economics.invoices.preview',
                params: { id: data.item.invoiceId },
              }"
              class="font-weight-bold"
            >
              #{{ data.value }}
            </b-link>
          </template>
          <template #cell(inspectionPointsPerformed)="data">
            <div class="d-flex justify-content-between align-items-center">
              <strong>{{ data.value.length }}</strong>
              <feather-icon
                icon="InfoIcon"
                v-b-tooltip.hover.right="
                  GetInspectionPointsPerformedToolTipText(data.value)
                "
              />
            </div>
          </template>
          <template #cell(passFailed)="data">
            <small class="p-0">
              {{ T("Web.InvoiceInspectionsPage.Failed", "Failed") }}:
              <span class="text-danger font-weight-bold">{{
                data.item.failedInspectionPointsCount
              }}</span
              ><br />
              {{ T("Web.InvoiceInspectionsPage.Passed", "Passed") }}:
              <span class="text-primary font-weight-bold">{{
                data.item.passedInspectionPointsCount
              }}</span
              ><br />
              {{
                T(
                  "Web.InvoiceInspectionsPage.PassedWithWarnings",
                  "Passed with warnings"
                )
              }}:
              <span class="text-warning font-weight-bold">{{
                data.item.passedWithWarningsInspectionPointsCount
              }}</span>
            </small>
          </template>
          <template #cell(actions)="data">
            <div class="text-right">
              <b-button
                v-if="data.item.hasReport"
                variant="flat-secondary"
                size="sm"
                @click="downloadReport(data.item.id)"
                >{{ T("Web.InvoiceInspectionsPage.DownloadReport", "Download report") }}
                <feather-icon icon="ChevronDownIcon"
              /></b-button>
              <feather-icon v-else icon="CheckIcon" class="text-primary" />
            </div>
          </template>
        </b-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BCard,
  BTable,
  BSpinner,
  VBTooltip,
  BButton,
  BLink,
} from "bootstrap-vue";
import InvoiceInspectionsService from "@/services/economics/invoice-inspections.service";
export default {
  components: {
    BCard,
    BTable,
    BSpinner,
    VBTooltip,
    BButton,
    BLink,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  mounted() {
    this.fetchApproved();
  },
  data() {
    return {
      tableFields: [
        {
          key: "invoiceNumber",
          label: this.T("Web.Generic.Invoices.InvoiceNumber", "Invoice number"),
          sortable: true,
        },
        {
          key: "inspectionPointsPerformed",
          label: this.T(
            "Web.InvoiceInspectionsPage.InspectionPointsPerformed",
            "Inspection points performed"
          ),
          sortable: false,
        },
        {
          key: "passFailed",
          label: this.T(
            "Web.InvoiceInspectionsPage.FailedParsed",
            "Failed / parsed"
          ),
          sortable: false,
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      inspectionsApproved: "invoiceInspections/inspectionsApproved",
      loading: "invoiceInspections/isLoading",
    }),
  },
  methods: {
    ...mapActions({
      fetchApproved: "invoiceInspections/fetchApproved",
    }),
    GetInspectionPointsPerformedToolTipText(inspectionPoints) {
      return inspectionPoints
        .map((p) => this.T(`Web.InvoiceInspectionsPage.InspectionPoints.${p}`))
        .join(", ");
    },
    downloadReport(inspectionId) {
      const service = new InvoiceInspectionsService();
      return service.getReport(inspectionId);
    },
  },
};
</script>