<template>
  <b-row>
    <b-col cols="12" class="m-auto">
      <transition name="fade" mode="out-in">
        <b-tabs content-class="mt-3" lazy>
          <b-tab :title='T("Web.InvoiceInspectionsPage.NeedsApprovalTabTitle", "Needs approval")' active>
            <awaiting-approval-list />
          </b-tab>
          <b-tab :title='T("Web.InvoiceInspectionsPage.ApprovedTabTitle", "Approved")' >
            <approved-inspections-list />
          </b-tab>
        </b-tabs>
      </transition>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BButton,
  BTable,
  BSpinner,
  VBTooltip,
  BContainer,
  BTabs,
  BRow,
  BCol,
  BTab,
} from "bootstrap-vue";
import AwaitingApprovalList from "./components/AwaitingApprovalList.vue";
import ApprovedInspectionsList from './components/ApprovedInspectionsList.vue';
export default {
  components: {
    BCard,
    BCardTitle,
    BButton,
    BTable,
    BSpinner,
    BContainer,
    BTabs,
    BRow,
    BCol,
    BTab,
    AwaitingApprovalList,
    ApprovedInspectionsList,
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  .card {
    .header {
      display: flex;
      justify-content: space-between;
      padding: 1.5rem;
      .card-title {
        margin-bottom: 0px;
      }
    }
  }
}
</style>
